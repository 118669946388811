import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import { Link } from "gatsby";
import Layout from "../../layouts/index";
import n10 from "../../images/ccnt.jpg";
import indaka from "../../images/indaka-raigama.png";
import IOT from "../../images/IOT-1024x432.jpg";
import device from "../../images/device-1024x353.png";
import NFooter from "../../components/nFooter";

const News = (props) => {
    return (
        <Layout bodyClass="page-news noteworthy">
            <Seo
                title="Revolutionizing the Coconut Industry through Digital EMP"
                description="Cultivation remains one of the key export crops in Sri Lanka. 
          With the diversity & demand of coconut-based products increasing 
          globally maintaining & productively managing Coconut 
          Estates would be key to the growth of the industry."
            />
            <div className="intro intro-banner" id="up">
                <div className="intro-banner-img">
                    <img alt="Revolutionizing the Coconut Industry" src={n10} />
                </div>

                <div className="container container-narrow intro-title noteworthy-title">
                    <div className="row">
                        <div className="col-12">
                            <div className="date">NEWS | NOVEMBER 19, 2021</div>
                            <h1>
                                Revolutionizing the Coconut Industry through Digital EMP
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container blog-page pt-4 mb-6">
                <p>Cultivation remains one of the key export crops in Sri Lanka. With the diversity & demand of coconut-based products increasing globally maintaining & productively managing Coconut Estates would be key to the growth of the industry.</p>
                <p>The Pain points associated with managing Coconut Estates have been one of the reasons for Estate owners to divest or sell off their plantations.</p>
                <p>In order to help the coconut plantations, address these pain points iTelaSoft has come up with a Estate Management Platform (EMP) which is a digital tool that enables the smooth functioning of the estates even at remote & multiple locations.</p>
                <p>The Estate App is an end-to-end Estate Management Platform covering all key functionalities on a Coconut Estate.

                </p>
                <p>Estate Management Organizations have several pain points which have been affecting their productivity & efficiency. Some of these issues are lag in information between onsite staff & head office, Problems in managing & monitoring crop activities, labour management to name a few.

                </p>
                <p>The Estate Platform addresses these pain points to ensure the smooth functioning of operations.
                </p>
                <p>Some of the key features include functionality for the field staff / supervisors using tablets which gives mobility and capture all the crop information, such as Crop Picks – Coconuts are picked by the casual workers and categorized based on certain criteria, Crop Disposal – Collect and sell each allocated harvest prepared for the registered buyers, Weeding – Monitoring and treatment management. Visual verification through the uploading of photographs is a feature available in this platform as well.

                </p>
                <p>Other features include management of casual workers – Estates employee daily wage workers and managing them is a key activity, multi-level approval and verification workflows, backend portal for admin staff to receive real-time data and take timely decisions & real-time dashboards.

                </p>
                <p>Speaking on the above Country Head Suresh Tissaaratchy said as a company we are working with partners to address their pain points through efficient, easy use & cost-effective solutions.

                </p>
                <p>iTelaSoft is a global technology company headquartered in Sydney, Australia. Founded in 2009 by a group of IT technologists,
                    iTelaSoft expanded its operations to include development centers in Sri Lanka and India.
                    Speaking about the company CEO Indaka Raigama said “ iTelaSoft  specialises in product development
                    and enterprise technology with a vision of becoming one of the best innovation partners to their customers.
                    We have helped many start-ups, established product vendors,
                    and large enterprises in Fintech, Telco and Edtech domains to achieve their business goals”
                </p>

                <p className="link">Source: <a href="https://www.dailymirror.lk/press-releases/Revolutionizing-the-Coconut-Industry-through-Digital-EMP/335-224628" rel="noreferrer noopener"
                    aria-label="xg-automation-platform"
                    target="_blank"> Revolutionizing the Coconut Industry through Digital EMP ...
                    dailymirror.lk </a></p>


                <NFooter
                    prv1="/noteworthy/iTelaSoft-announces-the-release-of-5G-capabilities-on-its-XG-platform"
                    prv2="/noteworthy/iTelaSoft-announces-the-release-of-5G-capabilities-on-its-XG-platform"
                    next1="/noteworthy/New-Addition-to-Salesforce-MVP-Hall-of-Fame-Sets-Global-Milestone"
                    next2="/noteworthy/New-Addition-to-Salesforce-MVP-Hall-of-Fame-Sets-Global-Milestone"
                    titlel="iTelaSoft Announces the Release of 5G Capabilities on its XG Platform"
                    titler="New Addition to Salesforce MVP Hall of Fame Sets Global Milestone"
                />
            </div>

        </Layout>
    );
};
export default News;